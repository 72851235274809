import React from "react";
import Layout from "../../../components/layout";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import { Helmet } from 'react-helmet';
import productsPageStyles from '../../../styles/productsPage.module.scss';
import data from '../../../data/fps';


export default function (props) {
    // console.log(props)
    const { fps551, fps955 } = useStaticQuery(
        graphql`query pressurizers {
            fps551: file(relativePath: {eq: "products-pressurizers.png"}) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
            fps955: file(relativePath: {eq: "pressurizer.png"}) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }`
    );
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${data.title} | Clean Air Filter`}</title>
        </Helmet>
        <section>
          {/* <h1><span></span> <span></span></h1> */}
          <h1>
            <span className="tail">
              <span className="head">{data.content.section1.h1.head}</span>
              {data.content.section1.h1.tail}
            </span>
          </h1>
          <article className="w-60">
            <p>{data.content.section1.p1}</p>
            <p>{data.content.section1.p2}</p>
          </article>
        </section>
        <section>
          <div className={productsPageStyles.product}>
            <div className={productsPageStyles.desc}>
              <h2>{data.content.section2.h2}</h2>
              <p>{data.content.section2.p}</p>
              <Link
                className="primary red"
                to="/products/filtration-protection-systems/fps55"
              >
                {data.content.section2.a}
              </Link>
            </div>
            <div className={productsPageStyles.fps55Img}>
              <Img fluid={fps551.childImageSharp.fluid} />
            </div>
          </div>
        </section>
        <hr></hr>
        <section>
          <div className={productsPageStyles.product}>
            <div className={productsPageStyles.desc}>
              <h2>{data.content.section3.h2}</h2>
              <p>{data.content.section3.p}</p>
              <Link
                className="primary red"
                to="/products/filtration-protection-systems/fps955"
              >
                {data.content.section3.a}
              </Link>
            </div>
            <div className={productsPageStyles.pressurizerImg}>
              <Img fluid={fps955.childImageSharp.fluid} />
            </div>
          </div>
        </section>
      </Layout>
    )
}
